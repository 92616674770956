import React from 'react'
import {navigate , Link, useStaticQuery, graphql} from 'gatsby'
import { Dropdown, Menu } from 'semantic-ui-react'

import * as styles from './styles/menu.module.css'


const MenuBanner = () => {
	const MenuTemp = useStaticQuery(graphql`
		query MenuBannerQuery {
			menu {
				result {
					name
					url
					children {
						squareImageHref
						name
						url
					}
				}
			}
		}
	`)

	const TopMenuList = [
		{
			"name": "Franchise",
			"url": "franchise",
			"children": []
		}
	];

	const EndMenuList = [
		{
			"name": "Negosyo",
			"url": "business",
			"children": []
		},
		{
			"name": "Seminars",
			"url": "seminar",
			"children": []
		},
		{
			"name": "Courses",
			"url": "course",
			"children": []
		},
		{
			"name": "Recipes, Videos and Guides",
			"url": "recipes",
			"children": [
				{
					"name": "Recipes",
					"url": "recipes",
					"children": []
				},
				{
					"name": "Stories and Tips",
					"url": "stories",
					"children": []
				},
				{
					"name": "How-To Guides",
					"url": "howto",
					"children": []
				}
			]
		}
	];

	const MakeMenuContent = (item, index, obj) => {
		if(item.children) {
			return (
				<React.Fragment key={item.name}>
					{item.children.length > 0 ? (
						<Dropdown
							onClick={()=>{
								navigate(`/${item.url}/`)
							}}
							item
							simple
							className={styles.bannerMenu}
						>
							<React.Fragment>
								<span>
									<Link to={`/${item.url}/`} className={styles.link}>
										{item.name}
									</Link>
								</span>
								<Dropdown.Menu
									onClick={(event)=>{
										event.stopPropagation()
									}}
									className={styles.bannerSubMenu}
								>
									{item.children.map(MakeMenuContent)}
								</Dropdown.Menu>
							</React.Fragment>
						</Dropdown>
					):(
						<Dropdown.Item
							as={Link}
							to={`/${item.url}/`}
							key={item.name}
							className={styles.bannerMenu}
						>
							{item.name}
						</Dropdown.Item>
					)}
				</React.Fragment>

			)
		}
		else {
			return (
				<Dropdown.Item
					as={Link}
					to={`/${item.url}/`}
					key={item.name}
					className={styles.bannerMenu}
				>
					{item.name}
				</Dropdown.Item>
			)
		}

	}
	const menu= MenuTemp.menu.result
	const result = TopMenuList.concat(menu).concat(EndMenuList).map(MakeMenuContent)
	return (
		<Menu vertical  className={`${styles.mainMenu} ${styles.mainMenuBanner}`}>
			{result}
		</Menu>

	)

}


export default MenuBanner